/* eslint-disable jsx-a11y/anchor-is-valid */
import logo from "../assets/logo.png";

import type { RootState } from "../redux/store";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { updateLocale } from "redux/slice/playerSlice";
import { useSearchParams } from "react-router-dom";
import { RIGHT_ALIGNED_LOCALES } from "Constants";

const LanguageDropdown = () => {
  const playerDetails = useSelector((state: RootState) => state.Player);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const playerData = useSelector((state: RootState) => state.Player);
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const valid_languages = [
    "ar_EG",
    "cs_CZ",
    "da_DK",
    "de_DE",
    "el_GR",
    "en_US",
    "es_ES",
    "fi_FI",
    "fr_FR",
    "he_IL",
    "id_ID",
    "it_IT",
    "ja_JP",
    "ko_KR",
    "ms_MY",
    "nb_NO",
    "nl_NL",
    "pl_PL",
    "pt_BR",
    "ro_RO",
    "ru_RU",
    "sr_RS",
    "sv_SE",
    "th_TH",
    "tr_TR",
    "vi_VN",
    "zh_CN",
    "zh_TW",
  ];


  let valid_languages_mapping = {
    "ar_EG": "العربية",
    "cs_CZ": "Čeština",
    "da_DK": "Dansk",
    "de_DE": "Deutsch",
    "el_GR": "Ελληνικά",
    "en_US": "English",
    "es_ES": "Español",
    "fi_FI": "Suomi",
    "fr_FR": "Français",
    "he_IL": "עברית",
    "id_ID": "Bahasa Indonesia",
    "it_IT": "Italiano",
    "ja_JP": "日本語",
    "ko_KR": "한국어",
    "ms_MY": "Melayu",
    "nb_NO": "Norsk (bokmål)",
    "nl_NL": "Nederlands",
    "pl_PL": "Polski",
    "pt_BR": "Português",
    "ro_RO": "Română",
    "ru_RU": "Русский",
    "sr_RS": "Српски",
    "sv_SE": "Svenska",
    "th_TH": "ภาษาไทย",
    "tr_TR": "Türkçe",
    "vi_VN": "Tiếng Việt'",
    "zh_CN": "简体中文",
    "zh_TW": "繁體中文",
  };

  return (
    <Menu as="div" className="relative inline-block text-left ">
      <div>
        <Menu.Button className="inline-flex w-full  bg-slate-500 justify-center gap-x-1.5 rounded-md bg-slate-500 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 p-2">
          {(valid_languages_mapping as any)[playerDetails.locale] || valid_languages_mapping.en_US}
          <div className="mt-1">
            <svg
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.5 4L6 8L9.5 4H2.5Z"
                fill="white"
              />
            </svg>
          </div>
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`${RIGHT_ALIGNED_LOCALES.includes(playerData.locale) ? 'left-0' : 'right-0'} absolute z-10 mt-2 w-40 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div className="py-1 max-h-96	overflow-y-auto">
            {valid_languages.map((item, idx) => {
              return (
                <Menu.Item key={idx}>
                  <div
                    onClick={() => {
                      dispatch(updateLocale(item));
                      changeLanguage(item);
                      searchParams.delete("locale");
                      searchParams.append("locale", item);
                      setSearchParams(searchParams);
                      if (playerData.page === 0 || playerData.page === 1 || playerData.page === 2) {
                        window.location.reload()
                      }
                    }}
                    className="inline-flex w-full justify-center gap-x-1.5 rounded-md text-sm font-semibold text-gray-900 shadow-sm ring-inset p-2"
                  >
                    {(valid_languages_mapping as any)[item]}
                  </div>
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default function Navbar() {
  const playerData = useSelector((state: RootState) => state.Player);
  const { t } = useTranslation();
  return (
    <div className="sticky top-0 z-50">
      <div className="flex flex-row bg-blue-950">
        <div className="basis-11/12 flex flex-row gap-5">
          <img className="w-16 h-16" src={logo} alt="zynga_logo" />
          <div className="grid content-center text-white text-base font-normal font-['Helvetica Neue'] leading-normal">
            {t("player_appeals")}
          </div>
        </div>
        <div className="basis-1/12 flex flex-row p-3 ">
          <div className="grid content-center text-white text-base font-normal font-['Helvetica Neue'] leading-normal">
            <LanguageDropdown />
          </div>
        </div>
      </div>
      {playerData.zid !== -1 && (
        <div className="flex flex-row bg-slate-100 justify-between p-2 md:hidden">
          <div>
            <div className="text-gray-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
              {t("player_id")}
            </div>
            <div className="mt-1 text-neutral-800 text-xs font-normal font-['Helvetica Neue'] leading-none">
              {playerData.zid.toString()}
            </div>
          </div>
          <div>
            <img
              className="w-8 h-8 rounded-md"
              src={playerData.appIcon}
              alt="game_logox"
            />
          </div>
        </div>
      )}
    </div>
  );
}
