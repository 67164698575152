/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useGetAppealMutation } from "redux/services/apiCall";
import { RootState } from "redux/store";
import { BallTriangle } from "react-loader-spinner";
import moment from "moment";
import {useSearchParams} from "react-router-dom";
// import { useCreateAppealMutation } from "redux/services/apiCall";

const AppealSubmitted = () => {
  const { t, i18n } = useTranslation();
  const [getAppeal, getAppealResponseInfo] = useGetAppealMutation();
  const playerData = useSelector((state: RootState) => state.Player);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const locale = searchParams.get("locale") || "en_US";
    i18n.changeLanguage(locale);
    getAppeal({
      report_id: playerData.reportId,
      zid: playerData.zid,
      signature: playerData.signature,
      locale: playerData.locale
    });
  }, [playerData]);

  return (
    <div className="flex justify-center w-full max-w-2xl">
      {getAppealResponseInfo.isSuccess && (
        <div className="grid w-full p-8">
          <div className="text-gray-950 text-2xl font-bold font-['Helvetica Neue'] leading-9">
            {t("appeal_submitted_title")}
          </div>
          <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
            {t("appeal_submitted_para1")}
          </div>
          <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
            {t("appeal_submitted_para2")}
          </div>
          <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
            {t("sincerely")}
          </div>
          <div className="text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
            {t("zynga_team")}
          </div>
          <div className="grid mt-4  bg-slate-100 rounded-md border border-slate-300 p-4 ">
            <div className="text-slate-500 text-sm font-normal font-['Helvetica Neue'] leading-tight">
              {t("report_details")}
            </div>
            <div className="w-full mt-4 h-px border border-slate-300"></div>
            <div className="mt-5 relative">
              <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
                {t("report_id")}
              </div>
              <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                {playerData.reportId}
              </div>
            </div>
            <div className="mt-5 relative">
              <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
                {t("game")}
              </div>
              <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                {String(playerData.appName)}
              </div>
            </div>
            <div className="mt-5 relative">
              <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
                {t("content_type")}
              </div>
              <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                {getAppealResponseInfo?.data?.data?.service}
              </div>
            </div>
            <div className="mt-5 relative">
              <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
                {t("offence_category")}
              </div>
              <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                {Object.entries(
                  getAppealResponseInfo?.data?.data?.categories
                ).map(([key, value]) => {
                  return (
                    <p key={key}>
                      {getAppealResponseInfo?.data?.data?.categories[key]}
                    </p>
                  );
                })}
              </div>
            </div>
            <div className="mt-5 relative">
              <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
                {t("action_taken")}
              </div>
              <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                {getAppealResponseInfo?.data?.data?.last_action}
              </div>
            </div>
            <div className="mt-5 relative">
              <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
                {t("submission_time")}
              </div>
              <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
              {moment.unix(getAppealResponseInfo?.data?.data?.created_at).utc().format("MMM DD, YYYY h:mm") + " GMT"}
              </div>
            </div>
            <div className="mt-5 relative">
              <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
                {t("appeal_reason")}
              </div>
              <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                {getAppealResponseInfo?.data?.data?.reason}
              </div>
            </div>
          </div>
        </div>
      )}
      {getAppealResponseInfo.isLoading && (
          <BallTriangle
            height={100}
            width={100}
            radius={5}
            color="#22304E"
            ariaLabel="ball-triangle-loading"
            visible
          />
        )}
    </div>
  );
};

export default AppealSubmitted;
