import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define a service using a base URL and expected endpoints

const headers = {
  "Content-type": "application/json"
};

let BASE_URL = "https://api.zynga.com/";
let CSREPORT_SERVICE = process.env.REACT_APP_CSREPORT

if (CSREPORT_SERVICE === 'csreport-staging'){
  BASE_URL = "https://internal.api.zynga.com/"
}

export const csreportAPI = createApi({
  reducerPath: "csreportApi",
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL, credentials: "include" }),
  endpoints: (builder) => ({
    verifySignature: builder.mutation({
      query: (data) => {
        return {
          url: `${CSREPORT_SERVICE}/v1/appeal/reports/${data.report_id}/id/${data.zid}/appeal/signature/${data.signature}/verify?locale=${data.locale}`,
          method: "POST",
          headers,
        };
      },
    }),
    createAppeal: builder.mutation({
      query: (data) => {
        return {
          url: `${CSREPORT_SERVICE}/v1/appeal/reports/${data.report_id}/id/${data.zid}/appeal`,
          method: "POST",
          body: data,
          headers,
        };
      },
    }),
    getAppeal: builder.mutation({
      query: (data) => {
        return {
          url: `${CSREPORT_SERVICE}/v1/appeal/reports/${data.report_id}/id/${data.zid}/appeal?signature=${data.signature}&locale=${data.locale}`,
          method: "GET",
          headers,
        };
      },
    }),
    getAppById: builder.mutation({
      query: (app_id) => {
        return {
          url: `csreport/v2/apps/${app_id}`,
          method: "GET",
          headers,
        };
      },
    }),
  }),
});

export const {
  useVerifySignatureMutation,
  useCreateAppealMutation,
  useGetAppealMutation,
  useGetAppByIdMutation
} = csreportAPI;
