/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import {
  useCreateAppealMutation,
  useGetAppealMutation,
} from "redux/services/apiCall";
import { updateAction, updatePage } from "redux/slice/playerSlice";
import { RootState } from "redux/store";
import { useSearchParams } from "react-router-dom";

const SubmitAppeal = (props: any) => {
  const { t, i18n } = useTranslation();
  const [createAppeal, createAppealResponseInfo] = useCreateAppealMutation();
  const [getAppeal, getAppealResponseInfo] = useGetAppealMutation();
  const [appealReason, setAppealReason] = useState("");
  const playerData = useSelector((state: RootState) => state.Player);
  const dispatch = useDispatch();
  const AppealsReasonParams = {
    max_length: 3000,
    warning_color: "text-zynga-yellow",
    limit_reached_color: "text-zynga-red",
    warning_limit: 2500,
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const locale = searchParams.get("locale") || "en_US";
    i18n.changeLanguage(locale);
    getAppeal({
      report_id: playerData.reportId,
      zid: playerData.zid,
      signature: playerData.signature,
      locale: playerData.locale,
    });
  }, []);

  useEffect(() => {
    if (createAppealResponseInfo.isSuccess) dispatch(updatePage(1));
    if (createAppealResponseInfo.isError) {
      if (
        "status" in createAppealResponseInfo.error &&
        "data" in createAppealResponseInfo.error
      ) {
        let err_msg: any = createAppealResponseInfo.error.data;
        if (createAppealResponseInfo.error.status === 403) {
          dispatch(updatePage(4));
        } else if (createAppealResponseInfo.error.status === 400) {
          // check error message
          if (err_msg.error_data.message === "Appeal exists") {
            dispatch(updatePage(2));
          } else if (err_msg.error_data.message === "cannot raise appeal") {
            dispatch(updateAction(err_msg.error_data.details.action));
            dispatch(updatePage(6));
          } // check error code
          else if (err_msg.error_data.code === 5012) {
            // Appeal can't be raised by reporter player for reported player in service delete in progress or delete complete state
            dispatch(updatePage(7));
          } else if (err_msg.error_data.code === 5010) {
            // Appeal can't be raised by reported player for reported player in pending delete in progress or pending delete complete state
            dispatch(updatePage(8));
          } else if (err_msg.error_data.code === 5011) {
            // Appeal can't be raised by reporter deleted
            dispatch(updatePage(9));
          } else {
            dispatch(updatePage(3));
          }
        } else if (createAppealResponseInfo.error.status === 404) {
          dispatch(updatePage(5));
        }
      }
    }
  }, [createAppealResponseInfo]);

  return (
    <div className="flex justify-center w-full max-w-2xl">
      <div className="grid w-full p-8">
        <div className="text-gray-950 text-2xl font-bold font-['Helvetica Neue'] leading-9">
          {t("submit_appeal")}
        </div>
        <div className="text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
          {t("submit_appeal_para1")}
        </div>
        <div className="grid mt-4  bg-slate-100 rounded-md border border-slate-300 p-4">
          <div className="text-slate-500 text-sm font-normal font-['Helvetica Neue'] leading-tight">
            {t("report_details")}
          </div>
          <div className="w-full mt-4 h-px border border-slate-300"></div>
          <div className="mt-5">
            <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
              {t("report_id")}
            </div>
            <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
              {playerData.reportId}
            </div>
          </div>
          <div className="mt-5 relative">
            <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
              {t("game")}
            </div>
            <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
              {String(playerData.appName)}
            </div>
          </div>
          <div className="mt-5 relative">
            <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
              {t("content_type")}
            </div>
            <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
              {props?.data?.service}
            </div>
          </div>
          <div className="mt-5 relative">
            <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
              {t("offence_category")}
            </div>
            <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
              {props?.data?.categories &&
                Object.entries(props?.data?.categories).map(([key, value]) => {
                  return <p key={key}>{props?.data?.categories[key]}</p>;
                })}
            </div>
          </div>
          <div className="mt-5 relative">
            <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
              {t("action_taken")}
            </div>
            <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
              {props?.data?.action}
            </div>
          </div>
          <div className="my-5 relative">
            <div className="left-0 top-0 text-slate-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
              {t("submission_time")}
            </div>
            <div className="left-0 top-[18px] text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
              {props?.data?.time}
              {moment
                .unix(props?.data?.report_time)
                .utc()
                .format("MMM DD, YYYY h:mm") + " GMT"}
            </div>
          </div>
        </div>
        <div className="w-full">
          <div
            className="text-gray-950 text-sm font-normal font-['Helvetica Neue'] leading-tight mt-5"
            dangerouslySetInnerHTML={{ __html: t("appeal_reason") }}
          ></div>
          <div>
            <textarea
              required={true}
              className="resize-none rounded-md w-full h-36 border border-slate-300 mt-2 p-3"
              placeholder={t("appeal_reason")}
              value={appealReason}
              onChange={(e) => setAppealReason(e.target.value)}
              maxLength={AppealsReasonParams.max_length}
            ></textarea>
            <span
              className={`flex justify-end font-bold ${
                appealReason.length >= AppealsReasonParams.warning_limit &&
                appealReason.length < AppealsReasonParams.max_length &&
                AppealsReasonParams.warning_color
              } ${
                appealReason.length == AppealsReasonParams.max_length &&
                AppealsReasonParams.limit_reached_color
              }`}
            >
              {appealReason.length}/{AppealsReasonParams.max_length}
            </span>
          </div>

          {createAppealResponseInfo.isError && (
            <div className="text-red-700 text-xs font-normal font-['Helvetica Neue'] leading-none flex gap-1">
              <div className="w-4 h-4">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.26749 2.00226C7.03715 0.668191 8.96257 0.66819 9.73223 2.00226L14.6351 10.5006C15.4043 11.8339 14.4421 13.5 12.9027 13.5H3.09698C1.55766 13.5 0.595374 11.8339 1.3646 10.5006L6.26749 2.00226ZM8 5.5C8.27614 5.5 8.5 5.72386 8.5 6V8.5C8.5 8.77614 8.27614 9 8 9C7.72386 9 7.5 8.77614 7.5 8.5V6C7.5 5.72386 7.72386 5.5 8 5.5ZM8 11C8.27614 11 8.5 10.7761 8.5 10.5C8.5 10.2239 8.27614 10 8 10C7.72386 10 7.5 10.2239 7.5 10.5C7.5 10.7761 7.72386 11 8 11Z"
                    fill="#BA2323"
                  />
                </svg>
              </div>
              {t("error")}
            </div>
          )}

          <button
            className="mt-2 bg-blue-500 w-full hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex justify-center"
            onClick={() =>
              createAppeal({
                signature: playerData.signature,
                reason: appealReason,
                source: "appeals_portal",
                report_id: playerData.reportId,
                zid: playerData.zid,
                locale: playerData.locale,
              })
            }
          >
            {!createAppealResponseInfo.isLoading && t("submit_appeal")}
            {createAppealResponseInfo.isLoading && (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubmitAppeal;
