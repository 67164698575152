import React from "react";
import { useTranslation } from "react-i18next";

const Forbidden = () => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-center w-full max-w-2xl">
      <div className="grid w-full p-8">
        <div className="text-gray-950 text-2xl font-bold font-['Helvetica Neue'] leading-9">
          {t("forbidden_error")}
        </div>
        <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
          {t("forbidden_para1")}
        </div>
        <div
          className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"
          dangerouslySetInnerHTML={{ __html: t("forbidden_para2") }}
        ></div>
        <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
          {t("forbidden_para3")}
        </div>
        <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
          {t("sincerely")}
        </div>
        <div className="text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
          {t("zynga_team")}
        </div>
      </div>
    </div>
  );
};

export default Forbidden;
