import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

const PlayerDetails = () => {
  const playerData = useSelector((state: RootState) => state.Player);
  const { t } = useTranslation();
  return (
    <div className="flex flex-row justify-between p-4 hidden md:flex">
      <div>
        <div className="text-gray-500 text-xs font-bold font-['Helvetica Neue'] leading-none">
          {t("player_id")}
        </div>
        <div className="mt-1 text-neutral-800 text-xs font-normal font-['Helvetica Neue'] leading-none">
          {playerData.zid.toString()}
        </div>
      </div>
      <div>
        <img
          className="w-8 h-8 rounded-md"
          src={playerData.appIcon}
          alt="game_logox"
        />
      </div>
    </div>
  );
};

export default PlayerDetails;
