import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import {useSearchParams} from "react-router-dom";

const CannotRaiseAppealByReportedReportedPendingDelete = () => {
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        const locale = searchParams.get("locale") || "en_US";
        i18n.changeLanguage(locale);
    })


    const playerData = useSelector((state: RootState) => state.Player);

    return (
        <div className="flex justify-center w-full max-w-2xl">
            <div className="grid w-full p-8">
                <div className="text-gray-950 text-2xl font-bold font-['Helvetica Neue'] leading-9">
                    {t("cannot_raise_appeal_title")}
                </div>
                <div
                    className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"
                    dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reported_player_reported_player_pending_delete_para1") }}
                />
                <div
                    className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"
                    dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reported_player_reported_player_pending_delete_para2") }}
                />
                <div
                    className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"
                    dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reported_player_reported_player_pending_delete_para3") }}
                />
                <ol style={{margin: 0, padding: 0, listStyleType: 'decimal', listStylePosition: 'inside'}}>
                    <li className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"  dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reported_player_reported_player_pending_delete_para4") }}/>
                    <li className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"  dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reported_player_reported_player_pending_delete_para5") }}/>
                    <li className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"  dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reported_player_reported_player_pending_delete_para6") }}/>
                </ol>
                <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                    {t("sincerely")}
                </div>
                <div className="text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
                    {t("zynga_team")}
                </div>
            </div>
        </div>
    );
};

export default CannotRaiseAppealByReportedReportedPendingDelete;
