import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en_US: {
        translation: require('./locales/en_US.json'),
      },
      fr_FR: {
        translation: require('./locales/fr_FR.json'),
      },
      cs_CZ: {
        translation: require('./locales/cs_CZ.json'),
      },
      ar_EG: {
        translation: require('./locales/ar_EG.json'),
      },
      da_DK: {
        translation: require('./locales/da_DK.json'),
      },
      de_DE: {
        translation: require('./locales/de_DE.json'),
      },
      el_GR: {
        translation: require('./locales/el_GR.json'),
      },
      es_ES: {
        translation: require('./locales/es_ES.json'),
      },
      fi_FI: {
        translation: require('./locales/fi_FI.json'),
      },
      he_IL: {
        translation: require('./locales/he_IL.json'),
      },
      id_ID: {
        translation: require('./locales/id_ID.json'),
      },
      it_IT: {
        translation: require('./locales/it_IT.json'),
      },
      ja_JP: {
        translation: require('./locales/ja_JP.json'),
      },
      ko_KR: {
        translation: require('./locales/ko_KR.json'),
      },
      ms_MY: {
        translation: require('./locales/ms_MY.json'),
      },
      nb_NO: {
        translation: require('./locales/nb_NO.json'),
      },
      nl_NL: {
        translation: require('./locales/nl_NL.json'),
      },
      pl_PL: {
        translation: require('./locales/pl_PL.json'),
      },
      pt_BR: {
        translation: require('./locales/pt_BR.json'),
      },
      ro_RO: {
        translation: require('./locales/ro_RO.json'),
      },
      ru_RU: {
        translation: require('./locales/ru_RU.json'),
      },
      sr_RS: {
        translation: require('./locales/sr_RS.json'),
      },
      sv_SE: {
        translation: require('./locales/sv_SE.json'),
      },
      th_TH: {
        translation: require('./locales/th_TH.json'),
      },
      tr_TR: {
        translation: require('./locales/tr_TR.json'),
      },
      vi_VN: {
        translation: require('./locales/vi_VN.json'),
      },
      zh_CN: {
        translation: require('./locales/zh_CN.json'),
      },
      zh_TW: {
        translation: require('./locales/zh_TW.json'),
      },
    },
    lng: 'en_US', // default language
    fallbackLng: 'en_US',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;