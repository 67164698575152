import {
  useGetAppByIdMutation,
  useVerifySignatureMutation,
} from "redux/services/apiCall";
import { useLocation } from "react-router-dom";
import { BallTriangle } from "react-loader-spinner";
import AppealClosed from "./AppealPages/AppealClosed";
import AppealSubmitted from "./AppealPages/AppealSubmitted";
import AppealUnderReview from "./AppealPages/AppealUnderReview";
import CannotRaiseAppeal from "./AppealPages/CannotRaiseAppeal";
import SubmitAppeal from "./AppealPages/SubmitAppeal";
import Forbidden from "./Forbidden";
import Navbar from "./Navbar";
import PlayerDetails from "./PlayerDetails";
import { useEffect } from "react";
import React from "react";
import type { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  PlayerData,
  updateAction,
  updateAppIcon,
  updateAppName,
  updatePage,
  updatePlayerDetails,
} from "redux/slice/playerSlice";
import { useTranslation } from "react-i18next";
import CannotRaiseAppealByReporterReportedDeleted from "./AppealPages/CannotRaiseAppealByReporterReportedDeleted";
import CannotRaiseAppealByReportedReportedPendingDelete from "./AppealPages/CannotRaiseAppealByReportedReportedPendingDelete";
import CannotRaiseAppealReporterDeletedOrReportedDeleted from "./AppealPages/CannotRaiseAppealReporterDeletedOrReportedDeleted";
import CannotRaiseAppealStatusCheck from "./AppealPages/CannotRaiseAppealStatusCheck";
import Footer from "./Footer";
import { RIGHT_ALIGNED_LOCALES } from "Constants";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function safeAtob(base64String: string) {
  try {
    var decodedString = atob(base64String);
    return decodedString;
  } catch (error) {
    return null;
  }
}

export default function Home() {
  let query = useQuery();
  const playerData = useSelector((state: RootState) => state.Player);
  const { t } = useTranslation();
  const [verifySignature, verifySignatureResponseInfo] =
    useVerifySignatureMutation();

  const [getAppInfo, getAppInfoResponse] = useGetAppByIdMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    let signature: string[] = [];
    if (query.has("sig")) {
      let bdecode = safeAtob(query.get("sig") || "");
      if (bdecode && bdecode?.length > 0) {
        signature = bdecode.split(":");
        if (signature.length >= 4) {
          let tempPlayerData: PlayerData = {
            reportId: signature[0],
            zid: Number(signature[1]),
            appId: Number(signature[2]),
            time: signature[3],
            locale: query.get("locale") || "en_US",
            signature: query.get("sig") || "",
          };
          dispatch(updatePlayerDetails(tempPlayerData));
          getAppInfo(Number(signature[2]));
          verifySignature({
            report_id: signature[0],
            zid: signature[1],
            signature: query.get("sig"),
            locale: query.get("locale"),
          });
        }
      }
    }
  }, []);

  useEffect(() => {
    if (getAppInfoResponse.isSuccess) {
      dispatch(updateAppIcon(getAppInfoResponse?.data?.data?.icon_url));
      dispatch(updateAppName(getAppInfoResponse?.data?.data?.app_name));
    }
  }, [getAppInfoResponse]);

  useEffect(() => {
    if (verifySignatureResponseInfo.isSuccess) {
      dispatch(updatePage(0));
    }
    if (verifySignatureResponseInfo.isError) {
      if (
        "status" in verifySignatureResponseInfo.error &&
        "data" in verifySignatureResponseInfo.error
      ) {
        let err_msg: any = verifySignatureResponseInfo.error.data;
        if (verifySignatureResponseInfo.error.status === 403) {
          dispatch(updatePage(4));
        } else if (verifySignatureResponseInfo.error.status === 400) {
          // check for error message
          if (err_msg.error_data.message === "Appeal exists") {
            dispatch(updatePage(2));
          } else if (err_msg.error_data.message === "cannot raise appeal") {
            dispatch(updateAction(err_msg.error_data.details.action));
            dispatch(updatePage(6));
          } else if(err_msg.error_data.code === 5012) {
            // Appeal can't be raised by reporter player for reported player in service delete in progress or delete complete state
            dispatch(updatePage(7));
          }else if(err_msg.error_data.code === 5010) {
            // Appeal can't be raised by reported player for reported player in pending delete in progress or pending delete complete state
            dispatch(updatePage(8));
          }else if(err_msg.error_data.code === 5011) {
            // Appeal can't be raised by player deleted
            dispatch(updatePage(9));
          }
          else{
            dispatch(updatePage(3));
          }
        } else if (verifySignatureResponseInfo.error.status === 404) {
          dispatch(updatePage(5));
        }
      }
    }
  }, [verifySignatureResponseInfo]);

  return (
    <div dir={RIGHT_ALIGNED_LOCALES.includes(playerData.locale) ? 'rtl': ''}>
      <div className="flex flex-col	min-h-screen">
        <Navbar />
        <div className="flex-grow">
          <div className="grid grid-flow-row justify-center justify-items-center w-full  md:p-4 md:bg-slate-100 gap-4">
            {!verifySignatureResponseInfo.isLoading && (
              <div>
                <div className="w-full h-fit md:border-2 md:rounded-lg bg-white mb-8 md:m-0">
                  <PlayerDetails />
                  <div className="w-full h-px border border-slate-100"></div>
                  {playerData.page === 0 && (
                    <SubmitAppeal
                      data={verifySignatureResponseInfo?.data?.data}
                    />
                  )}
                  {playerData.page === 1 && <AppealSubmitted />}
                  {playerData.page === 2 && <AppealUnderReview />}
                  {playerData.page === 3 && <AppealClosed />}
                  {playerData.page === 4 && <Forbidden />}
                  {playerData.page === 5 && <CannotRaiseAppeal />}
                  {playerData.page === 6 && <CannotRaiseAppealStatusCheck />}
                  {playerData.page === 7 && (
                    <CannotRaiseAppealByReporterReportedDeleted />
                  )}
                  {playerData.page === 8 && (
                    <CannotRaiseAppealByReportedReportedPendingDelete />
                  )}
                  {playerData.page === 9 && (
                    <CannotRaiseAppealReporterDeletedOrReportedDeleted />
                  )}
                </div>
              </div>
            )}
            {verifySignatureResponseInfo.isLoading && (
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#22304E"
                ariaLabel="ball-triangle-loading"
                visible
              />
            )}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
