import { configureStore } from '@reduxjs/toolkit'
import reportReducer from './slice/reportSlice'
import playerReducer from './slice/playerSlice'
import { setupListeners } from '@reduxjs/toolkit/query';
import { csreportAPI } from './services/apiCall';

export const store = configureStore({
  reducer: {
    report: reportReducer,
    Player: playerReducer,
    [csreportAPI.reducerPath]: csreportAPI.reducer
  },

  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(csreportAPI.middleware)
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
setupListeners(store.dispatch);
