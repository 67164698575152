/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../assets/logo.png";

const Footer = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer">
      <div className="desktop-view">
        <img className="zynga-image" src={logo} />
        <div className="custom-flex-footer">
          <div className="text-wrapper">
            <a
              href="https://www.zynga.com/support/"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.CustomerSupport")}
            </a>
          </div>
          <div className="rectangle"></div>
          <div className="text-wrapper">
            <a
              href="https://www.zynga.com/privacy/policy"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.PrivacyPolicy")}
            </a>
          </div>
          <div className="rectangle"></div>
          <div className="text-wrapper">
            <a
              href="https://www.zynga.com/legal/terms-of-service"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.TermsOfService")}
            </a>
          </div>
          <div className="rectangle"></div>
          <div className="text-wrapper">
            <a
              href="https://www.take2games.com/ccpa"
              target="_blank"
              rel="noreferrer"
            >
              {t("footer.SaleOfInfo")}
            </a>
          </div>
        </div>
      </div>
      <div className="copyright">
        <p className="message">
          {`©${new Date().getFullYear()} Take-Two Interactive Software`},
          {` ${t("footer.AllRightsReserved")}`}
        </p>
      </div>
    </div>
  );
};

export default Footer;
