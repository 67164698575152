import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import {useSearchParams} from "react-router-dom";

const CannotRaiseAppealByReporterReportedDeleted = () => {
    const { t, i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(()=>{
        const locale = searchParams.get("locale") || "en_US";
        i18n.changeLanguage(locale);
    })


    const playerData = useSelector((state: RootState) => state.Player);

    return (
        <div className="flex justify-center w-full max-w-2xl">
        <div className="grid w-full p-8">
        <div className="text-gray-950 text-2xl font-bold font-['Helvetica Neue'] leading-9">
            {t("cannot_raise_appeal_title")}
    </div>
    <div
    className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"
    dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reporting_player_reported_player_deleted_para1") }}
></div>
            <div
                className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal"
                dangerouslySetInnerHTML={{ __html: t("appeal_cant_be_raised_by_reporting_player_reported_player_deleted_para2") }}
            ></div>
    <div className="mt-3 text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
        {t("sincerely")}
    </div>
    <div className="text-gray-950 text-base font-normal font-['Helvetica Neue'] leading-normal">
        {t("zynga_team")}
    </div>
    </div>
    </div>
);
};

export default CannotRaiseAppealByReporterReportedDeleted;
