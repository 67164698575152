import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface PlayerData {
  locale: string;
  reportId: string;
  appId: Number;
  zid: Number;
  time: any;
  signature: string;
  page?: Number;
  appName?: string;
  appIcon?: string;
  action?: string;
}

const initialState: PlayerData = {
  locale: "en_IN",
  reportId: "",
  appId: -1,
  zid: -1,
  time: null,
  signature: "",
  page: 4,
  action: "",
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    updatePlayerDetails: (state, action: PayloadAction<PlayerData>) => {
      state.appId = action.payload.appId;
      state.locale = action.payload.locale;
      state.reportId = action.payload.reportId;
      state.zid = action.payload.zid;
      state.time = action.payload.time;
      state.signature = action.payload.signature;
      state.page = action.payload.page;
    },
    updatePage: (state, action: PayloadAction<Number>) => {
      state.page = action.payload;
    },
    updateAppName: (state, action: PayloadAction<string>) => {
      state.appName = action.payload;
    },
    updateAppIcon: (state, action: PayloadAction<string>) => {
      state.appIcon = action.payload;
    },
    updateLocale: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    updateAction: (state, action: PayloadAction<string>) => {
      state.action = action.payload
    }
  },
});

// Action creators are generated for each case reducer function
export const { updatePlayerDetails, updatePage, updateAppIcon, updateAppName, updateLocale, updateAction } = playerSlice.actions;

export default playerSlice.reducer;
