import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface ReportState {
  language: string;
}

const initialState: ReportState = {
  language: "en_US",
};

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    updateLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateLanguage } = reportSlice.actions;

export default reportSlice.reducer;
